import { departmentGroups, standaloneDepartments } from "./departments.config";

export type Department = {
  id: string;
  name: string;
  slug?: string;
};

export type DepartmentGroup = {
  slug?: string;
  name: string;
  departments: Department[];
};

// Standalone departments require both name and slug, otherwise they can't be accessed
export type StandaloneDepartment = Department & {
  name: string;
  slug: string;
};

export const getDepartmentGroupByDepartmentId = (departmentId: string) => {
  return departmentGroups.find((group) =>
    group.departments.some((dep) => dep.id === departmentId),
  );
};

export const getDepartmentGroupBySlug = (slug: string) => {
  return departmentGroups.find((group) => group.slug === slug);
};

export const getDepartmentsBySlug = (slug: string): Department[] => {
  // Is it a department group?
  const departmentGroup = getDepartmentGroupBySlug(slug);
  if (departmentGroup) {
    return departmentGroup.departments;
  }

  // Is it a standalone department?
  const standaloneDepartment = standaloneDepartments.find(
    (dep) => dep.slug === slug,
  );
  if (standaloneDepartment) {
    return [standaloneDepartment];
  }

  // Is it a single department within a department group?
  const departmentGroupDepartment = departmentGroups
    .flatMap((group) => group.departments)
    .find((dep) => dep.slug === slug);
  if (departmentGroupDepartment) {
    return [departmentGroupDepartment];
  }

  // It's not a department after all
  return [];
};

export const isValidSlugForDepartmentId = (
  slug: string,
  departmentId: string,
) => {
  return getDepartmentsBySlug(slug).some((dep) => dep.id === departmentId);
};

export const getHardcodedDealers = (slug: string) => {
  const dealers = getDepartmentsBySlug(slug);
  const dealerGroup = getDepartmentGroupBySlug(slug);

  return { dealers, dealerGroup };
};

export const getDepartmentNameById = (departmentId: string) => {
  const department = departmentGroups
    .flatMap((group) => group.departments)
    .find((dep) => dep.id === departmentId);
  return department?.name;
};
