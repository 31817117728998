"use client";

import { css, styled } from "styled-components";
import { BodyText } from "@gnist/design-system";
import { tokens } from "@gnist/themes/tokens.css.js";

const Badge = styled(BodyText)<{ $isCompact?: boolean }>`
  ${(props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${tokens.color.secondary};
    color: ${tokens.color["on-secondary"]};
    border-radius: 100%;
    font-weight: ${tokens.typeface.weight.semibold};
    height: ${props.$isCompact ? tokens.size.s : tokens.size.m};
    width: ${props.$isCompact ? tokens.size.s : tokens.size.m};
    font-size: ${props.$isCompact ? "12px" : tokens.typeface.size.s};
  `}
`;

export const CounterBadge = ({
  value,
  isCompact,
}: {
  value: string;
  isCompact?: boolean;
}) => {
  return <Badge $isCompact={isCompact}>{value}</Badge>;
};
