"use client";

import { css, styled } from "styled-components";
import {
  breakpointTablet,
  DetailText,
  Heading2Text,
} from "@gnist/design-system";
import { CampaignBadge } from "./campaignBadge";
import { DealerCampaign } from "./campaign.types";
import { tokens } from "@gnist/themes/tokens.css.js";

const Title = styled(Heading2Text)`
  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: ${tokens.spacing.xs};
  }
`;

const Body = styled.p`
  padding-top: ${tokens.spacing.base};

  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: ${tokens.spacing.xs};
  }
`;

const Disclaimer = styled(DetailText)`
  color: ${tokens.color["on-disabled"]};
  padding-top: ${tokens.spacing.s};

  @media screen and (max-width: ${breakpointTablet}) {
    padding-top: ${tokens.spacing.xs};
  }
`;

const BadgeWrapper = styled.div<{ $hideOnMobile?: boolean }>`
  ${(props) => css`
    margin-bottom: ${tokens.spacing.s};

    @media screen and (max-width: ${breakpointTablet}) {
      display: ${props.$hideOnMobile ? "none" : "flex"};
    }
  `}
`;

export const CampaignContent = ({
  campaign,
  hideBadgeOnMobile,
  departmentSlug,
}: {
  campaign: DealerCampaign;
  hideBadgeOnMobile?: boolean;
  departmentSlug?: string;
}) => {
  return (
    <div>
      <BadgeWrapper $hideOnMobile={hideBadgeOnMobile}>
        <CampaignBadge dealerGroupName={departmentSlug || ""} />
      </BadgeWrapper>
      <Title>{campaign.heading}</Title>
      <Body>{campaign.body}</Body>
      <Disclaimer>{campaign.disclaimer}</Disclaimer>
    </div>
  );
};
