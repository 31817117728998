import { styled } from "styled-components";
import {
  GhostButton,
  InfoCard,
  Heading2Text,
  DetailText,
} from "@gnist/design-system";
import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { TradeInContent } from "@/components/tradeIn/tradeInContent";
import { TradeInModal } from "@/components/tradeIn/tradeInModal";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { tokens } from "@gnist/themes/tokens.css.js";

export const Title = styled.div`
  ${Heading2Text.selector} {
    font-size: ${tokens.typeface.size.base};
    margin: 0;
  }
`;

export const DescriptionText = styled(DetailText)`
  font-size: ${tokens.typeface.size.s};
  color: ${tokens.color["on-disabled"]};
  margin: ${tokens.spacing.base} 0 ${tokens.spacing.xs};
`;

export const TradeInCard = ({
  departmentSlug,
}: {
  departmentSlug?: string;
}) => {
  const { tradeInPrice, setIsShowingTradeInModal } = useTradeInContext();
  const isTradeInEnabled = !departmentSlug;

  useEffect(() => {
    if (isTradeInEnabled && !tradeInPrice) {
      datadogRum.addAction("Sees trade-in button in overview page");
    }
  }, [isTradeInEnabled, tradeInPrice]);

  if (!isTradeInEnabled) {
    return;
  }
  return (
    <>
      <InfoCard>
        {tradeInPrice ? (
          <TradeInContent context="overview" />
        ) : (
          <>
            <Title>
              <Heading2Text $as="h2">Har du en bil å bytte inn?</Heading2Text>
            </Title>
            <DescriptionText>
              Skriv inn verdien på bilen din og se priser med innbytte.
            </DescriptionText>
            <GhostButton
              onClick={() => {
                setIsShowingTradeInModal(true);
                datadogRum.addAction(
                  "Clicks button to add trade-in car in overview page",
                );
              }}
            >
              Legg til innbytte
            </GhostButton>
          </>
        )}
      </InfoCard>
      <TradeInModal context="overview" />
    </>
  );
};
