"use client";

import { styled } from "styled-components";
import { breakpointDesktop } from "@gnist/design-system";
import { tokens } from "@gnist/themes/tokens.css.js";

export const FilterAndCarsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  margin-top: ${tokens.spacing.m};

  @media screen and (min-width: ${breakpointDesktop}) {
    grid-template-columns: fit-content(296px) auto;
    margin-top: ${tokens.spacing["3xl"]};
    gap: ${tokens.spacing.xl};
  }
`;
