"use client";

import { useEffect, useState } from "react";
import { IconButton } from "@gnist/design-system";
import { breakpointDesktop } from "@gnist/design-system";
import { css, styled } from "styled-components";
import { MobileFilterMenu } from "./MobileFilterMenu";
import { CounterBadge } from "@/components/filters/CounterBadge";
import {
  getCountFromCheckFilter,
  getCountFromRangeFilter,
} from "@/components/filters/utils/getFilterCount";
import { useFilters } from "@/components/filters/hooks/useFilters";
import { TradeInCard } from "@/components/tradeIn/tradeInCard";
import { tokens } from "@gnist/themes/tokens.css.js";

const FilterButtonSection = styled.div<{ $isTopOfPage: boolean }>`
  ${(props) => css`
    display: flex;
    justify-content: center;
    align-content: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: ${tokens.color.background};
    padding: ${tokens.spacing.xs} 0;
    width: 100vw;
    margin: 0 calc(${tokens.spacing.l} * -1) ${tokens.spacing.m};
    box-shadow: ${props.$isTopOfPage ? tokens.elevation.low : "unset"};
    transition: box-shadow ease-in 200ms;

    @media screen and (min-width: ${breakpointDesktop}) {
      display: none;
    }
  `}
`;

const TradeInWrapper = styled.div`
  padding-bottom: ${tokens.spacing.s};
  > * {
    box-shadow: ${tokens.elevation.low};
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none;
  }
`;

interface Props {
  departmentIds?: string[];
  departmentSlug?: string;
}

export const MobileFilter = ({ departmentIds, departmentSlug }: Props) => {
  const {
    departmentsFilter,
    brandsWithModels,
    fuelFilter,
    priceInput,
    modelYearInput,
    mileageInput,
  } = useFilters();

  const [showFilterMenuMobile, setShowFilterMenuMobile] = useState(false);
  const [isTopOfPage, setIsTopOfPage] = useState(false);

  const filterCount =
    getCountFromCheckFilter(brandsWithModels) +
    getCountFromRangeFilter(modelYearInput) +
    getCountFromRangeFilter(mileageInput) +
    getCountFromRangeFilter(priceInput) +
    getCountFromCheckFilter(fuelFilter) +
    getCountFromCheckFilter(departmentsFilter);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const distanceToTop: number =
        document.getElementById("mobile-filter")?.getBoundingClientRect()
          ?.top || 0;

      if (distanceToTop <= 0) {
        setIsTopOfPage(true);
      } else {
        setIsTopOfPage(false);
      }
    });
  }, []);

  return (
    <>
      <FilterButtonSection id="mobile-filter" $isTopOfPage={isTopOfPage}>
        <IconButton
          icon={"filter_icon"}
          label={"Filtrer listen"}
          showLabel={"right"}
          onClick={() => setShowFilterMenuMobile(true)}
        />
        {filterCount !== 0 && (
          <CounterBadge value={filterCount.toString()} isCompact />
        )}
      </FilterButtonSection>
      <MobileFilterMenu
        departmentIds={departmentIds}
        isOpen={showFilterMenuMobile}
        handleClose={() => setShowFilterMenuMobile(false)}
      />
      <TradeInWrapper>
        <TradeInCard departmentSlug={departmentSlug} />
      </TradeInWrapper>
    </>
  );
};
