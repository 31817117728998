"use client";

import { css, styled } from "styled-components";
import React from "react";
import { getThemeFromDealerName } from "../../design-system/ThemeProvider";
import { tokens } from "@gnist/themes/tokens.css.js";

const Badge = styled.div<{ $isMollerBrand: boolean }>`
  ${(props) => css`
    width: fit-content;
    background-color: ${props.$isMollerBrand
      ? tokens.color.tertiary
      : tokens.palette.warning["60"]};
    display: flex;
    padding: ${tokens.size.base} ${tokens.size.xs};
    align-items: center;
    border-radius: ${tokens.radius.input};

    p {
      font-weight: 500;
      font-size: ${tokens.typeface.size.s};
    }
  `}
`;

export const CampaignBadge = ({
  dealerGroupName,
}: {
  dealerGroupName: string;
}) => {
  const theme = () => getThemeFromDealerName(dealerGroupName);

  return (
    <Badge $isMollerBrand={theme() === "mollerbil"}>
      <p>Kampanje</p>
    </Badge>
  );
};
