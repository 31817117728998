import { FilterModal } from "./filterModal";
import { FilterMenuItem } from "./filterMenuItem";
import { useFilters } from "../hooks/useFilters";
import React, { useState } from "react";
import { DefaultCheckboxGroup } from "../defaultCheckboxGroup";
import { RangeFilterSection } from "../rangeFilterSection";
import { GhostButton, PrimaryButton } from "@gnist/design-system";
import { styled } from "styled-components";
import { useCarFetcher } from "../../carList/fetcher";
import { FilterLabel } from "../types/filters.types";
import { tokens } from "@gnist/themes/tokens.css.js";
import {
  getCountFromCheckFilter,
  getCountFromRangeFilter,
} from "@/components/filters/utils/getFilterCount";
import { translateFilterLabels } from "@/utils/translateFilter";

const StyledFooterSection = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: ${tokens.spacing.s};
  padding: ${tokens.spacing.s} ${tokens.spacing.l};
  background-color: ${tokens.color.background};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  departmentIds?: string[];
}

export const MobileFilterMenu = ({
  isOpen,
  handleClose,
  departmentIds,
}: Props) => {
  const {
    brandsWithModels,
    toggleBrandsWithModels,
    fuelFilter,
    toggleFuelFilter,
    priceInput,
    setPriceInput,
    modelYearInput,
    setModelYearInput,
    mileageInput,
    setMileageInput,
    departmentsFilter,
    toggleDepartmentsFilter,
    equipmentFilter,
    toggleEquipmentFilter,
    salesTypeFilter,
    toggleSalesTypesFilter,
    onClickSearchRangeFilter,
    resetAllFilters,
  } = useFilters();

  const { carsCount } = useCarFetcher(departmentIds);

  const [filterToDisplay, setFilterToDisplay] = useState<
    FilterLabel | undefined
  >();
  const onClickClose = () => {
    setFilterToDisplay(undefined);
    handleClose();
  };

  return (
    <FilterModal
      isOpen={isOpen}
      handleClose={() => onClickClose()}
      handleGoBack={() => setFilterToDisplay(undefined)}
      headingText={
        filterToDisplay ? translateFilterLabels(filterToDisplay) : "Filter"
      }
      hasParentCategory={filterToDisplay !== undefined}
    >
      {!filterToDisplay && (
        <>
          {departmentsFilter && departmentsFilter.length > 0 && (
            <FilterMenuItem
              label={translateFilterLabels("Departments")}
              onClick={() => setFilterToDisplay("Departments")}
              chosenCount={getCountFromCheckFilter(departmentsFilter)}
            />
          )}
          <FilterMenuItem
            label={translateFilterLabels("BrandsWithModels")}
            onClick={() => setFilterToDisplay("BrandsWithModels")}
            chosenCount={getCountFromCheckFilter(brandsWithModels)}
          />
          <FilterMenuItem
            label={translateFilterLabels("ModelYear")}
            onClick={() => setFilterToDisplay("ModelYear")}
            chosenCount={getCountFromRangeFilter(modelYearInput)}
          />
          <FilterMenuItem
            label={translateFilterLabels("Mileage")}
            onClick={() => setFilterToDisplay("Mileage")}
            chosenCount={getCountFromRangeFilter(mileageInput)}
          />
          <FilterMenuItem
            label={translateFilterLabels("Price")}
            onClick={() => setFilterToDisplay("Price")}
            chosenCount={getCountFromRangeFilter(priceInput)}
          />
          <FilterMenuItem
            label={translateFilterLabels("Fuel")}
            onClick={() => setFilterToDisplay("Fuel")}
            chosenCount={getCountFromCheckFilter(fuelFilter)}
          />
          <FilterMenuItem
            label={translateFilterLabels("Equipment")}
            onClick={() => setFilterToDisplay("Equipment")}
            chosenCount={getCountFromCheckFilter(equipmentFilter)}
          />
          <FilterMenuItem
            label={translateFilterLabels("SalesType")}
            onClick={() => setFilterToDisplay("SalesType")}
            chosenCount={getCountFromCheckFilter(salesTypeFilter)}
          />
        </>
      )}
      {filterToDisplay === "Departments" && departmentsFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("Departments")}
          checkboxFilters={departmentsFilter}
          toggleFilter={toggleDepartmentsFilter}
        />
      )}
      {filterToDisplay === "BrandsWithModels" && brandsWithModels && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("BrandsWithModels")}
          checkboxFilters={brandsWithModels}
          toggleFilter={toggleBrandsWithModels}
        />
      )}
      {filterToDisplay === "ModelYear" && (
        <RangeFilterSection
          headingText={translateFilterLabels("ModelYear")}
          startRangeLabel="Fra år"
          endRangeLabel="Til år"
          formatInputValueWithSpacing={false}
          filter={modelYearInput}
          updateFilter={setModelYearInput}
          onSearch={onClickSearchRangeFilter}
        />
      )}
      {filterToDisplay === "Mileage" && (
        <RangeFilterSection
          headingText={translateFilterLabels("Mileage")}
          startRangeLabel="Fra km"
          endRangeLabel="Til km"
          formatInputValueWithSpacing={true}
          filter={mileageInput}
          updateFilter={setMileageInput}
          onSearch={onClickSearchRangeFilter}
        />
      )}

      {filterToDisplay === "Price" && (
        <RangeFilterSection
          headingText={translateFilterLabels("Price")}
          startRangeLabel="Fra pris"
          endRangeLabel="Til pris"
          formatInputValueWithSpacing={true}
          filter={priceInput}
          updateFilter={setPriceInput}
          onSearch={onClickSearchRangeFilter}
        />
      )}
      {filterToDisplay === "Fuel" && fuelFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("Fuel")}
          checkboxFilters={fuelFilter}
          toggleFilter={toggleFuelFilter}
        />
      )}

      {filterToDisplay === "Equipment" && equipmentFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("Equipment")}
          checkboxFilters={equipmentFilter}
          toggleFilter={toggleEquipmentFilter}
        />
      )}
      {filterToDisplay === "SalesType" && salesTypeFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("SalesType")}
          checkboxFilters={salesTypeFilter}
          toggleFilter={toggleSalesTypesFilter}
        />
      )}
      <StyledFooterSection>
        <GhostButton onClick={() => resetAllFilters()}>Nullstill</GhostButton>
        <PrimaryButton
          onClick={() => onClickClose()}
          loading={{ isLoading: carsCount == null, loadingText: "Laster..." }}
        >
          {`Vis resultat (${carsCount})`}
        </PrimaryButton>
      </StyledFooterSection>
    </FilterModal>
  );
};
