"use client";

import { styled } from "styled-components";
import { LinkCard } from "@gnist/design-system";
import {
  TextSkeleton,
  RectangularSkeleton,
} from "@gnist/design-system/building-blocks/skeletons";
import { List, ListItem } from "@/components/carList/carList";
import { tokens } from "@gnist/themes/tokens.css.js";

const LoadingCard = styled(LinkCard)`
  background-color: ${tokens.color.surface};
  height: 100%;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-items: flex-start;
  row-gap: ${tokens.spacing.xs};
  > div {
    justify-content: flex-start;
  }
`;

export const LoadingCarList = () => {
  return (
    <List>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <ListItem key={item}>
          <LoadingCard>
            <LoadingContent>
              <RectangularSkeleton rounded />
              <TextSkeleton height="1.5em" width="100%" />
              <TextSkeleton height="1.5em" width="100%" />
              <TextSkeleton height="1.5em" width="80%" />
            </LoadingContent>
          </LoadingCard>
        </ListItem>
      ))}
    </List>
  );
};
