import { getEnvironment } from "@/environment/environment";

type SanityDatasets = "bruktbil-development" | "bruktbil-production";

export const getSanityDataset = (): SanityDatasets => {
  const env = getEnvironment();

  // TODO: Setup stage dataset in Sanity
  if (env === "prod") {
    return "bruktbil-production";
  }

  return "bruktbil-development";
};
