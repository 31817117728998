import { Icon, Heading2Text } from "@gnist/design-system";
import React from "react";
import { ActionCard } from "@gnist/design-system";
import { styled } from "styled-components";
import { CounterBadge } from "@/components/filters/CounterBadge";
import { tokens } from "@gnist/themes/tokens.css.js";

const ItemCard = styled(ActionCard)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: solid 1px;
  border-bottom-color: ${tokens.color.outline};
  background-color: transparent;
  color: ${tokens.color["on-background"]};
  box-shadow: unset;
  border-radius: unset;
`;

const EndSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${tokens.spacing.s};
`;

interface Props {
  label: string;
  children?: React.ReactNode;
  onClick: () => void;
  chosenCount?: number;
}
export const FilterMenuItem = ({ label, onClick, chosenCount }: Props) => {
  return (
    <ItemCard onClick={onClick}>
      <Heading2Text>{label}</Heading2Text>
      <EndSection>
        {!!chosenCount && <CounterBadge value={chosenCount.toString()} />}
        <Icon className="chevron_right" icon="chevron_right" />
      </EndSection>
    </ItemCard>
  );
};
