"use client";

import { styled } from "styled-components";
import { CarCard } from "./carCard";
import { UnstyledList } from "../styled-common/unstyledList";
import { useCarFetcher } from "./fetcher";
import {
  breakpointDesktop,
  breakpointTablet,
  Heading2Text,
  DetailText,
  GhostButton,
} from "@gnist/design-system";
import { Campaign } from "../campaign/campaign";
import { LoadingCarList } from "@/components/carList/loadingCarList";
import { useDealerCampaign } from "../campaign/campaignProvider";
import { tokens } from "@gnist/themes/tokens.css.js";

export const List = styled(UnstyledList)`
  --gap: ${tokens.spacing.s};
  --cols: 3;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  max-width: 990px;
  justify-content: left;

  @media screen and (max-width: ${breakpointDesktop}) {
    justify-content: center;
  }

  @media screen and (max-width: 1464px) {
    --cols: 2;
  }

  @media screen and (max-width: 560px) {
    --cols: 1;
  }
`;

export const ListItem = styled.li`
  min-width: 250px;
  max-width: 320px;
  flex-basis: calc(
    100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1)
  );

  @media screen and (max-width: ${breakpointTablet}) {
    max-width: 348px;
  }
`;

const LoadMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${tokens.spacing.xl};
  gap: ${tokens.spacing.s};
  align-items: center;
`;

const NoCarsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.xs};
  align-items: center;
  width: 400px;
  text-align: center;
`;

const StyledGhostButton = styled(GhostButton)`
  min-width: 160px;
`;

const CampaignWrapper = styled.div`
  max-width: 700px;
  min-width: 100%;
  border-radius: ${tokens.radius.card};
  box-shadow: ${tokens.elevation.low};
  background-color: ${tokens.color.surface};
  display: flex;

  @media screen and (max-width: ${breakpointTablet}) {
    max-width: 348px;
    min-width: unset;
  }
`;

interface Props {
  departmentIds?: string[];
  departmentSlug?: string;
  dealerName?: string;
}

export const CarList = ({
  departmentIds,
  departmentSlug,
  dealerName,
}: Props) => {
  const { cars, error, isLoading, setSize, carsCount } =
    useCarFetcher(departmentIds);

  const { dealerCampaign } = useDealerCampaign(dealerName);

  if (error) return <div>Klarte ikke laste innhold</div>;

  const carsShown = cars.length;
  const canShowMoreCars = carsCount !== null && carsCount > carsShown;

  const firstThreeCars = cars.splice(0, 3); // mutates and removes 3 items from `cars` list as well

  if (isLoading) {
    return <LoadingCarList />;
  }

  return (
    <div>
      <List>
        {firstThreeCars.map((car, i) => (
          <ListItem key={`${car.carId}_${i}`}>
            <CarCard car={car} departmentSlug={departmentSlug} />
          </ListItem>
        ))}
        {dealerCampaign && carsShown > 0 && (
          <CampaignWrapper>
            <Campaign
              campaign={dealerCampaign}
              departmentSlug={departmentSlug}
            />
          </CampaignWrapper>
        )}
        {cars.map((car, i) => (
          <ListItem key={`${car.carId}_${i}`}>
            <CarCard car={car} departmentSlug={departmentSlug} />
          </ListItem>
        ))}
      </List>
      <LoadMoreSection>
        {carsShown ? (
          <DetailText>
            Viser {carsShown} av {carsCount} biler
          </DetailText>
        ) : (
          <NoCarsInfo>
            <Heading2Text>Fant ingen biler</Heading2Text>
            <p>
              Det finnes dessverre ingen biler med de valgte filtrene eller
              utsalgsstedene.
            </p>
          </NoCarsInfo>
        )}
        {canShowMoreCars && (
          <StyledGhostButton onClick={() => setSize((size) => size + 1)}>
            Vis flere
          </StyledGhostButton>
        )}
      </LoadMoreSection>
    </div>
  );
};
