import { DetailText } from "@gnist/design-system";
import { styled } from "styled-components";

type props = {
  propertyDesc: string;
  propertyVal: string | number;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  overflow: hidden;
`;

const CarProperty = ({ propertyDesc, propertyVal }: props) => {
  return (
    <Wrapper>
      <DetailText style={{ fontSize: "12px" }}>{propertyDesc}</DetailText>
      <div>{propertyVal}</div>
    </Wrapper>
  );
};

export default CarProperty;
