"use client";

import clsx from "clsx";
import { styled } from "styled-components";
import { Icon } from "@gnist/design-system";
import { useSwitch, UseSwitchParameters } from "@mui/base/useSwitch";
import { tokens } from "@gnist/themes/tokens.css.js";

const BasicSwitchRoot = styled.span`
  box-sizing: border-box;
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 52px;
  height: 30px;
  cursor: pointer;
  background-color: ${tokens.color.surface};
  border: 1px solid ${tokens.color.primary};
  border-radius: 24px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: ${tokens.palette.neutral["80"]};
  }

  &.Switch-focusVisible {
    box-shadow: 0 0 0 2px ${tokens.color.interactive};
  }

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: ${tokens.color.primary};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    &.Switch-focusVisible {
      box-shadow: 0 0 0 2px ${tokens.color.interactive};
    }
  }
`;

const BasicSwitchInput = styled.input`
  box-sizing: border-box;
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const BasicSwitchThumb = styled.span`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  top: 5px;
  left: 4px;
  border-radius: 16px;
  background-color: ${tokens.color.primary};
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &.Switch-checked {
    left: 25px;
    background-color: ${tokens.color.surface};
  }
`;

// The usage of this component is to be replaced by Switch component from Gnist design-system when it's ready

export const Switch = (props: UseSwitchParameters) => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    "Switch-checked": checked,
    "Switch-disabled": disabled,
    "Switch-focusVisible": focusVisible,
  };

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)}>
        {checked && <Icon icon="check" size="s" />}
      </BasicSwitchThumb>
      <BasicSwitchInput {...getInputProps()} />
    </BasicSwitchRoot>
  );
};
