"use client";

import { breakpointDesktop, FilterChip } from "@gnist/design-system";
import { styled } from "styled-components";
import { Collapse } from "@/components/collapse";
import { useFilters } from "@/components/filters/hooks/useFilters";
import { tokens } from "@gnist/themes/tokens.css.js";

const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${breakpointDesktop}) {
    display: none;
  }
`;

const ChipsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${tokens.spacing.xs} ${tokens.spacing.s};
`;

export const DepartmentFilterDesktop = () => {
  const { departmentsFilter, toggleDepartmentsFilter } = useFilters();

  return (
    <StyledCollapse
      id="department-filter-chips-desktop"
      backgroundColorToken={tokens.color.background}
      collapsedHeight="85"
      buttonLabel={{ expand: "Vis mer", collapse: "Vis mindre" }}
      showCollapsedOverlay={false}
    >
      <ChipsWrapper>
        {departmentsFilter?.map(
          (dep) =>
            dep.name && (
              <FilterChip
                key={dep.key}
                label={dep.name}
                selected={dep.isActive}
                onClick={() => toggleDepartmentsFilter(dep.key)}
              />
            ),
        )}
      </ChipsWrapper>
    </StyledCollapse>
  );
};
